import * as React from "react";

const SVGRing = (props) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={82}
		height={82}
		viewBox="0 0 82 82"
		{...props}
	>
		<g
			id="Ellipse_13"
			data-name="Ellipse 13"
			fill="none"
			stroke="#dbeff2"
			strokeWidth={10}
		>
			<circle cx={41} cy={41} r={41} stroke="none" />
			<circle cx={41} cy={41} r={36} fill="none" />
		</g>
	</svg>
);

export default SVGRing;
