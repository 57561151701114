import React from "react";
import { Helmet } from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";

const LocalBusinessSchema = () => {
	const data = useStaticQuery(
		graphql`
			query {
				heroImg3: wpMediaItem(title: { eq: "Zoom-Pilates-Thumnail-Image-2" }) {
					localFile {
						publicURL
					}
				}
				reviews {
					reviewCount
				}
				schemaLogo: file(relativePath: { eq: "logo.png" }) {
					publicURL
				}
			}
		`
	);
	const heroImg = data.heroImg3?.localFile.publicURL;
	const reviewCount = data.reviews.reviewCount;
	const localBusinessSchema = {
		"@context": "https://schema.org",
		"@type": "LocalBusiness",
		"@id": "https://www.eastbournepilates.com/",
		name: "Eastbourne Pilates",
		legalName: "Eastbourne Pilates t/a Tracy Fuller",
		description:
			"Looking for pilates in Eastbourne, East Sussex? We provide group and 1-2-1 classes as well as Reformer Pilates. Book a FREE assessment today.",
		url: "https://www.eastbournepilates.com/",
		priceRange: "$",
		areaServed: [
			{
				"@type": "City",
				name: "Eastbourne",
				sameAs: "https://en.wikipedia.org/wiki/Eastbourne",
			},
		],
		email: "info@eastbournepilates.com",
		currenciesAccepted: "GBP",
		numberOfEmployees: "1",
		location: {
			"@type": "City",
			name: "Eastbourne",
			sameAs: "https://en.wikipedia.org/wiki/Eastbourne",
		},
		foundingLocation: {
			"@type": "City",
			name: "Eastbourne",
			sameAs: "https://en.wikipedia.org/wiki/Eastbourne",
		},
		slogan: "placeholder",
		telephone: "07776197775",
		address: {
			"@type": "PostalAddress",
			addressLocality: "Eastbourne",
			addressRegion: "East Sussex",
			postalCode: "BN22 7AQ",
			streetAddress: "Royal Parade",
			addressCountry: "United Kingdom",
		},
		geo: {
			"@type": "GeoCoordinates",
			latitude: "50.7736513",
			longitude: "0.2992487",
		},
		hasMap: "https://goo.gl/maps/",
		openingHours: "Mo,Tu,We,Th,Fr 09:00-17:00",
		openingHoursSpecification: [
			{
				"@type": "OpeningHoursSpecification",
				closes: "17:00",
				dayOfWeek: "http://schema.org/Monday",
				opens: "09:00",
			},
			{
				"@type": "OpeningHoursSpecification",
				closes: "17:00",
				dayOfWeek: "http://schema.org/Tuesday",
				opens: "09:00",
			},
			{
				"@type": "OpeningHoursSpecification",
				closes: "17:00",
				dayOfWeek: "http://schema.org/Wednesday",
				opens: "09:00",
			},
			{
				"@type": "OpeningHoursSpecification",
				closes: "17:00",
				dayOfWeek: "http://schema.org/Thursday",
				opens: "09:00",
			},
			{
				"@type": "OpeningHoursSpecification",
				closes: "17:00",
				dayOfWeek: "http://schema.org/Friday",
				opens: "09:00",
			},
		],
		founder: {
			"@type": "Person",
			name: "Tracy Fuller",
			id: "https://www.eastbournepilates.com/about",
			url: "https://www.eastbournepilates.com/about",
			description:
				"Tracy Fuller is an Eastbourne based Pilates Teacher who specialises in improving your wellbeing.",
		},
		aggregateRating: {
			"@type": "AggregateRating",
			bestRating: "5",
			worstRating: "1",
			ratingValue: "4.9",
			reviewCount: `${reviewCount}`,
			url: "https://www.eastbournepilates.com/success-stories",
		},
		contactPoint: {
			"@type": "ContactPoint",
			"@id": "https://www.eastbournepilates.com/contact-us",
			url: "https://www.eastbournepilates.com/contact-us",
			// "image": `${data.schemaLogo.publicURL}`,
			name: "Tracy Fuller",
			description: "Eastbourne Pilates Contact Information",
			telephone: "07776197775",
			contactType: "Form",
			availableLanguage: "en",
			areaServed: [
				{
					"@type": "City",
					name: "Eastbourne",
					sameAs: "https://en.wikipedia.org/wiki/Eastbourne",
				},
			],
		},
		logo: ``,
		image: ``,
		sameAs: [
			"https://www.facebook.com/eastbournepilatesandnutrition",
			"https://heyyoga.co.uk/04520716/Eastbourne_Pilates_and_Nutrition_with_Tracy_Fuller",
			"https://all-opening-hours.co.uk/04520716/Eastbourne_Pilates_and_Nutrition_with_Tracy_Fuller",
			"https://nextdoor.co.uk/pages/eastbourne-pilates-eastbourne-gb-eng/",
		],
	};

	return (
		<Helmet>
			<script type="application/ld+json">
				{JSON.stringify(localBusinessSchema)}
			</script>
		</Helmet>
	);
};

export default LocalBusinessSchema;
