import { useStaticQuery, graphql } from "gatsby";
import * as React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Link from "../components/link";
import Layout from "../components/layout";
import Button from "react-bootstrap/Button";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import LocalBusinessSchema from "../components/schema/localBusinessSchema";
import { Helmet } from "react-helmet";
import PhotoLeft from "../components/photo-left";
import Navigation from "../components/navigation";
import SVGRing from "../components/SVG/ring";
import SVGCircle from "../components/SVG/circle";
import SVGVeryThinRing from "../components/SVG/very-thin-ring";
import { checkPropertiesForNull } from "../../utils";
import { SafeHtmlParser } from "../components/safeHtmlParser";

const AboutPage = () => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          siteUrl
        }
      }
      pageData: wpPage(slug: { eq: "about" }) {
        aboutFields {
          aboutBanner {
            aboutBannerHeading
          }
          aboutLeftImageRightContent {
            aboutRightHeading
            aboutRightSubheading
            aboutRightContent
            aboutLeftImage {
              node {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      formats: [AUTO, WEBP]
                      quality: 100
                      transformOptions: { cropFocus: CENTER, fit: CONTAIN }
                      layout: CONSTRAINED
                      placeholder: BLURRED
                    )
                  }
                }
              }
            }
          }
          aboutSimpleContentCta {
            aboutSimpleContentText
            aboutSimpleContentButton {
              title
              target
              url
            }
          }
        }
        seoFields {
          metaTitle
          metaDescription
          opengraphTitle
          opengraphDescription
          productSchema
          image {
            node {
              altText
              publicUrl
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    formats: [AUTO, WEBP]
                    quality: 100
                    transformOptions: { cropFocus: CENTER, fit: CONTAIN }
                    layout: CONSTRAINED
                    placeholder: BLURRED
                  )
                  original {
                    width
                    height
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  const {
    pageData: { seoFields, aboutFields },
  } = data;

  const { aboutBanner, aboutLeftImageRightContent, aboutSimpleContentCta } =
    aboutFields;

  const siteUrl = data.site.siteMetadata.siteUrl;

  const breadcrumb = {
    "@context": "http://schema.org",
    "@type": "BreadcrumbList",
    itemListElement: [
      {
        "@type": "ListItem",
        position: "1",
        name: "Home",
        item: {
          url: `${siteUrl}`,
          id: `${siteUrl}`,
        },
      },
      {
        "@type": "ListItem",
        position: "2",
        name: "About",
        item: {
          url: `${siteUrl}/about`,
          id: `${siteUrl}/about`,
        },
      },
    ],
  };

  return (
    <Layout>
      <Helmet>
        {" "}
        <script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
      </Helmet>
      <LocalBusinessSchema />
      <GatsbySeo
        title={seoFields?.metaTitle}
        description={seoFields?.metaDescription}
        language="en"
        openGraph={{
          type: "website",
          url: `${siteUrl}`,
          title: `${seoFields?.opengraphTitle}`,
          description: `${seoFields?.opengraphDescription}`,
          images: [
            {
              url: `${seoFields?.image?.node.localFile.publicURL}`,
              width: `${seoFields?.image?.node.localFile.childImageSharp.original.width}`,
              height: `${seoFields?.image?.node.localFile.childImageSharp.original.height}`,
              alt: `${seoFields?.image?.node.altText}`,
            },
          ],
        }}
      />

        <section
          className="position-relative"
          style={{
            background:
              "transparent linear-gradient(180deg, #E0F1F9 0%, #FFFFFF 100%) 0% 0% no-repeat padding-box",
            opacity: 1,
          }}
        >
          <Navigation background="none" />
          <SVGVeryThinRing
            style={{ left: "-17%" }}
            className="position-absolute top--70 d-none d-xl-block "
          />
          <SVGRing
            style={{ width: "25rem", height: "25rem" }}
            className="position-absolute top--60 end-10 d-none d-xl-block"
          />
          <SVGCircle
            style={{ width: "3.5rem" }}
            className="position-absolute end-10 bottom--30 d-none d-xl-block"
          />
          <Container>
            {" "}
            <Row>
              <Col>
                <h1 className="text-primary mt-8 mb-5 text-center display-4 jost-bold">
                  {aboutBanner?.aboutBannerHeading || "About Tracy"}
                </h1>
              </Col>
            </Row>
          </Container>
        </section>


      {aboutLeftImageRightContent &&
        !checkPropertiesForNull(aboutLeftImageRightContent, [
          "aboutRightHeading",
        ]) && (
          <section>
            <PhotoLeft
              img={
                aboutLeftImageRightContent?.aboutLeftImage.node?.localFile
                  .childImageSharp.gatsbyImageData
              }
              imgAlt={aboutLeftImageRightContent?.aboutLeftImage.node?.altText}
              heading={aboutLeftImageRightContent?.aboutRightHeading}
              sheading={aboutLeftImageRightContent?.aboutRightSubheading}
              text={
                <SafeHtmlParser
                  htmlContent={aboutLeftImageRightContent?.aboutRightContent}
                />
              }
              height="32rem"
            />
          </section>
        )}
      {aboutSimpleContentCta &&
        !checkPropertiesForNull(aboutSimpleContentCta, [
          "aboutSimpleContentText",
        ]) && (
          <section>
            <Container>
              <Row>
                <Col>
                  <SafeHtmlParser
                    htmlContent={aboutSimpleContentCta?.aboutSimpleContentText}
                  />
                  {aboutSimpleContentCta?.aboutSimpleContentButton &&
                    aboutSimpleContentCta.aboutSimpleContentButton.url && (
                      <div className="text-center mb-6">
                        <Button
                          className="cta-btn px-4 mt-4 w-100 w-md-auto"
                          as={Link}
                          to={aboutSimpleContentCta.aboutSimpleContentButton.url}
                        >
                          {aboutSimpleContentCta.aboutSimpleContentButton.title}
                        </Button>
                      </div>
                    )}
                </Col>
              </Row>
            </Container>
          </section>
        )}
    </Layout>
  );
};

export default AboutPage;
